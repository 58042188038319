import Reviews from "@/components/home/reviews/Reviews.vue";
import Form from "@/components/form/Form.vue";
import ScrollToBtn from '@/components/common/scrollto/ScrollToBtn.vue'
import getServices from "@/api/services";
import VueSlickCarousel from 'vue-slick-carousel'

export default {
	components: {Reviews, Form, ScrollToBtn, VueSlickCarousel},
	metaInfo() {
		return {
			title: this.currentService?.metaTitle ?? 'Service title',
			meta: [
				{ name: 'description', content: this.currentService?.metaDescription ?? 'Service description' },
				{ name: 'keywords', content: this.currentService?.metaKeywords ?? 'Service keywords' },
			]
		}
	},
	created() {
		getServices.getAll(this)
	},
	data() {
		return {
			dialog: false,
			dialogImg: null,
			settings: {
				arrows: true,
				dots: true,
			},
			showVideo: false,
			loading: false
		}
	},
	computed: {
		currentService() {
			if (this.state?.services?.data) {
				return this.state.services.data.find(service => service.urlName === this.$route.params.urlName)
			}
			return null
		},
		video () {
			return !!(this.currentService?.file?.length && this.currentService?.file !== 'undefined' && this.currentService?.file !== 'null');
		},
		images() {
			if (this.currentService?.images) {
				if (typeof this.currentService.images === 'string') {
					return JSON.parse(this.currentService.images)
				}
				return this.currentService.images
			}
			return []
		},
		mobileImages() {
			if (this.currentService?.mobileImages) {
				if (typeof this.currentService.mobileImages === 'string') {
					return JSON.parse(this.currentService.mobileImages)
				}
				return this.currentService.mobileImages
			}
			return []
		},
		bodyImages() {
			if (this.currentService?.bodyImages) {
				if (typeof this.currentService.bodyImages === 'string') {
					return JSON.parse(this.currentService.bodyImages)
				}
				return this.currentService.bodyImages
			}
			return []
		}
	},
	methods: {
		openDialog (dialogImg) {
			this.dialogImg = dialogImg
			// this.dialog = true
		},
		playVideo() {
			if(this.showVideo === false) {
				this.$refs.videoRef.play();
				this.showVideo = true
			}
			else {
				this.$refs.videoRef.pause();
				this.showVideo = false
			}
		},
		updatePaused() {
			this.loading = false
		},
		showLoading(){
			this.loading = true
		}
	}
}
